import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ImageCropPicker from 'react-native-image-crop-picker';
import { Platform } from "react-native";
import { WithStyles, } from "@material-ui/core/styles";
import { GridColDef, GridValueGetterParams, GridSelectionModel } from '@material-ui/data-grid';


interface IDeletePlaylist {
    message?: string,
    errors: {
        message?: string
    }
}
interface IPlaylist {
    play_list_colection?: {
        data: IPlaylistcollection[]
    },
    errors?: {
        message?: string
    }
}
export interface IPlaylistcollection {
    id?: string | number,
    type?: string,
    attributes?: {
        title?: string,
        song_id?: number[],
        description?: string,
        image?: string
    }
}

export interface ISongsAttributesDTO {
    title: string;
    artists: string[];
    about: string;
    duration: number | null;
    language: string | null;
    lyrics: string | null;
    music_file: string;
    cover_pic: string | null;
}

export interface ISongsDTO {
    id: string;
    type: string;
    attributes: ISongsAttributesDTO;
}

interface ISongsList {
    data?: ISongsDTO[],
    errors: {
        message?: string
    }
}


interface ViewPlayList {
    play_list_colection?: {
        playlist_id?: number,
        playlist_title?: string,
        songs_details?: [],
        description?: string,
        image?: string
    },
    errors: {
        message?: string
    }
}

export interface PlaylistCollectionDTO {
  id?: number;
  title?: string;
  song_id?: number[]
}
export interface PlaylistCollectionDTOWithAttributes {
  id?: number;
  title?: string;
  song_id?: number[]
  attributes: SongsAttributesDTO;
}

export interface Item {
  isSelected: boolean;
  id: string;
  type: string;
  attributes: SongsAttributesDTO;
  // Define other properties as needed
}


export interface SongsAttributesDTO {
  title: string;
  artists: string[];
  about: string;
  duration: number | null;
  language: string | null;
  lyrics: string | null;
  music_file: string;
  cover_pic: string | null;
  song_id: number[]
  description: string | null;
  image: File | null
  id: number
}

export interface File {
  filename: string | undefined;
  name: string | undefined;
  type: string | null;
  uri: string | null;
}

export interface SongsDTO {
  id: string;
  type: string;
  attributes: SongsAttributesDTO;
  isSelected: boolean
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props  extends WithStyles  {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  showErr: boolean;
  errorMessage: string;
  allPlaylistData: PlaylistCollectionDTOWithAttributes[];
  selectedPlaylist: PlaylistCollectionDTO;
  allSonglistData: SongsDTO[];
  selectedSongIds: number[];
  selectedSongs: SongsDTO[];
  playlistDialog: boolean;
  playlistDialogMode: string;
  playlistInputValue: string;
  description: string;
  page: number;
  rowsPerPage: number;
  isImage: File | Blob | null;
  allPlaylistDataWeb: IPlaylistcollection[];
  selectedPlaylistWeb: IPlaylistcollection;
  allSongsColumnInfo: GridColDef[];
  allSonglistDataWeb: ISongsDTO[];
  selectedSongIdsWeb: GridSelectionModel;
  selectedSongsWeb: ISongsDTO[];
  playlistName: string;
  logo: Blob | string;
  viewPlayListSongsColumns: GridColDef[];
  savedSongsData: ISongsDTO[]
  viewImageUrl: string,
  selectedPlayListId: number
  // isImage: imageObject;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PlaylistController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      token: "",
      showErr: false,
      errorMessage: "",
      allPlaylistData: [],
      selectedPlaylist: {},
      allSonglistData: [],
      selectedSongIds: [],
      selectedSongs: [],
      playlistDialog: false,
      playlistDialogMode: 'add',
      playlistInputValue: '',
      description: '',
      page: 0,
      rowsPerPage: 5,
      isImage: null,
      allPlaylistDataWeb: [],
      selectedPlaylistWeb: {},
      allSongsColumnInfo: [
          { field: 'id', headerName: 'ID' },
          { field: 'title', headerName: 'Title', width: 250, valueGetter: (params: GridValueGetterParams) => params.row.attributes.title },
          { field: 'about', headerName: 'About', width: 275, valueGetter: (params: GridValueGetterParams) => params.row.attributes.about }
      ],
      allSonglistDataWeb: [],
      selectedSongIdsWeb: [],
      selectedSongsWeb: [],
      playlistName: "",
      logo: "",
      viewPlayListSongsColumns: [
          { field: 'id', headerName: 'ID' },
          { field: 'title', headerName: 'Title', width: 250, valueGetter: (params: GridValueGetterParams) => params.row.title },
          { field: 'about', headerName: 'About', width: 275, valueGetter: (params: GridValueGetterParams) => params.row.about }
      ],
      savedSongsData: [],
      viewImageUrl: "",
      selectedPlayListId: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.fetchPlaylistApiCallId)
      {
        if (responseJson && (responseJson.errors || responseJson.error)) {
          this.setState({ errorMessage: "Something went wrong get playlist------", showErr: true })
        } else if (responseJson) {
          this.setState({ allPlaylistData: responseJson.play_list_colection.data });
        }
      }
      else if (apiRequestCallId === this.apiCallIdSavePlaylist) {
        if (responseJson && responseJson.play_list_colection) {
          this.loadPlaylistFn();
          this.handlePlaylistClickClose();
        }
      }
      else if (apiRequestCallId === this.deletePlaylistApiCallId) {
        if (responseJson && (responseJson.errors || responseJson.error)) {
        } else if (responseJson && responseJson.message) {
          this.loadPlaylistFn();
        }
      } else if (apiRequestCallId === this.fetchSonglistApiCallId) {
        if (responseJson && responseJson.data) {
          let temp: Item[] = []
          responseJson.data.map((item: Item) => {
            item.isSelected = false
            temp.push(item)
          })
          this.setState({ allSonglistData: temp });
        }
      }else  if (apiRequestCallId === this.apiCallIdGetPlayList) {
        this.getAllPlaylistsApiResponce(responseJson)
    } else if (apiRequestCallId === this.apiCallIdSavePlaylistWeb) {
        this.savePlaylistApiResponce(responseJson)
    } else if (apiRequestCallId === this.apiCallIdDeletePlaylist) {
        this.deletePlaylistApiResponce(responseJson)
    } else if (apiRequestCallId === this.apiCallIdGetSongs) {
        this.getSongsApiResponce(responseJson)
    } else if (apiRequestCallId === this.apiCallIdViewPlaylist) {
        this.viewPlaylistApiResponce(responseJson)
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  fetchPlaylistApiCallId: string = "";
  addOrUpdatePlayListApiCallId: string = "";
  deletePlaylistApiCallId: string = "";
  fetchSonglistApiCallId: string = "";
  apiCallIdSavePlaylist: string = "";
  token:string="";
  apiCallIdGetPlayList: string = "";
  apiCallIdSavePlaylistWeb: string = "";
  apiCallIdDeletePlaylist: string = "";
  apiCallIdGetSongs: string = "";
  apiCallIdViewPlaylist: string = ""


  componentDidMount = async () => {
    let token = configJSON.token
    this.token = token;
    this.loadPlaylistFn();
    this.loadSongListFn();
    this.getPlayList();
  }

  loadPlaylistFn = () => {
    const header = { "Content-Type": configJSON.apiContentType, token: this.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchPlaylistApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.playListCreatedListApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateSelectedPlaylistFn = (flag: string, selectedRow: PlaylistCollectionDTOWithAttributes) => {
    this.handlePlaylistClickOpen(flag);
    const selectedSongIDs = selectedRow.attributes.song_id;
    const selectedSongs = this.state.allSonglistData.filter(songRow =>
      selectedSongIDs.includes(parseInt(songRow.id))
    );
    let tempSelectedSongsId: Item[] = []
    this.state.allSonglistData.map((item: Item) => {
      selectedRow.attributes.song_id.map((songId: number) => {
        if (songId == parseInt(item.id)) {
          item.isSelected = true
        }
      })
      tempSelectedSongsId.push(item)
    })
    this.setState({
      selectedPlaylist: selectedRow,
      playlistInputValue: selectedRow.attributes.title || "",
      description: selectedRow.attributes.description || "",
      selectedSongIds: selectedRow.attributes.song_id || [],
      selectedSongs: selectedSongs,
      allSonglistData: tempSelectedSongsId,
      isImage: selectedRow.attributes.image || null
    })
  }

  handleSaveOrUpdatePlaylist = () => {
    const header = {
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    let bodyFormData = new FormData();
    bodyFormData.append("title", this.state.playlistInputValue);
    bodyFormData.append("description", this.state.description);

    if (this.state.isImage instanceof File) {
      bodyFormData.append("image", this.state.isImage);
    }
    for (let song of this.state.selectedSongIds) {
      bodyFormData.append("song_id[]", this.state.selectedSongIds.toString())
    }

    this.apiCallIdSavePlaylist = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), bodyFormData);
    if (this.state.playlistDialogMode === "add") {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addPlayListApiEndPoint);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod);
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  UpdatePlaylistData = () => {
    const header = {
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const updateApiBodyForm = new FormData()
    updateApiBodyForm.append("title", this.state.playlistInputValue);
    updateApiBodyForm.append("description", this.state.description);
    if (this.state.isImage instanceof File) {
      updateApiBodyForm.append("image", this.state.isImage);
    }
    for (let song of this.state.selectedSongIds) {
      updateApiBodyForm.append("song_id[]", song.toString())
    }
    this.apiCallIdSavePlaylist = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), updateApiBodyForm)
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateSongApiEndPoint + this.state.selectedPlaylist.id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteSelectedPlaylistFn = (selectedRow: PlaylistCollectionDTO) => {
    const header = { "Content-Type": configJSON.apiContentType, token: this.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deletePlaylistApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.deleteSongApiEndPoint + selectedRow.id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  loadSongListFn = async () => {
    const header = { "Content-Type": configJSON.apiContentType, token: this.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.fetchSonglistApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.songListApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePlaylistClickOpen = (flag: string) => {
    this.setState({
      playlistDialog: true,
      playlistDialogMode: flag,
      playlistInputValue: "",
      description: "",
      selectedPlaylist: {},
      selectedSongIds: []
    })
  }

  handlePlaylistClickClose = () => {
    let temp: Item[] = []
    this.state.allSonglistData.map((item) => {
      item.isSelected = false
      temp.push(item)
    })
    this.setState({ allSonglistData: temp });

    this.setState({
      playlistDialog: false,
      playlistDialogMode: 'add',
      selectedSongIds: [],
      selectedSongs: [],
      allSonglistData: temp,
      isImage: null
    })
  }


  selectedSongs = (selectedModel: SongsDTO) => {

    const selectedSongId = selectedModel.id.toString();
    const selectedSongs = this.state.allSonglistData.filter((itemRow: SongsDTO) =>
      selectedSongId.includes(itemRow.id),
    );

    let tempSongsId: number[] = this.state.selectedSongIds
    let tempAllSongData: Item[] = []

    this.state.allSonglistData.map((item) => {

      if (item.id == selectedModel.id) {
        if (!item.isSelected) {
          tempSongsId.push(parseInt(selectedModel.id))
        } else {
          const filteredArray = tempSongsId.filter(item => item.toString() != selectedModel.id);
          tempSongsId = filteredArray
        }
        item.isSelected = !item.isSelected
      }
      tempAllSongData.push(item)
    })

    this.setState({
      selectedSongIds: tempSongsId,
      allSonglistData: tempAllSongData,
      selectedSongs: selectedSongs
    });
  }



  handlePlaylistInputChange = (text: string) => {
    this.setState({
      playlistInputValue: text
    })
  }
  handleDescription = (text: string) => {
    this.setState({
      description: text
    })
  }

  renderImageCropPicker = () => {
    ImageCropPicker?.openPicker({
      width: 300,
      height: 400,
      cropping: true,
    }).then((image) => {
      let imgObj: File = {
        uri: image.path,
        type: image.mime,
        name: Platform.OS === 'ios' ? image.filename : "IMG_0002.JPG",
        filename: Platform.OS === 'ios' ? image.filename : "IMG_0002.JPG",
      }
      this.setState({ isImage: imgObj })
    });
  }

  getPlayList = () => {
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.token,    
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCallIdGetPlayList = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.playListCreatedListApiEndPoint
        );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

getAllPlaylistsApiResponce = (responseJson: IPlaylist) => {
    if (responseJson && responseJson.errors) {
        this.setState({ errorMessage: "Something went wrong", showErr: true })
    } else if (responseJson && !responseJson.errors) {
        const playlistData = responseJson?.play_list_colection?.data || []
        this.setState({ allPlaylistDataWeb: playlistData });
    }
}

savePlaylistApiResponce = (responseJson: IPlaylist) => {
    if (responseJson && responseJson.play_list_colection) {
        this.getPlayList();
        this.showAlert('Alert', this.state.playlistDialogMode === 'add' ? configJSON.playlist.create : configJSON.playlist.update)
        this.handlePlaylistClickClose();
    } else if (responseJson && responseJson.errors) {
        this.setState({ errorMessage: "Something went wrong", showErr: true })
    }
}

deletePlaylistApiResponce = (responseJson: IDeletePlaylist) => {
    if (responseJson && responseJson.message) {
        this.getPlayList();
        this.showAlert("Alert", configJSON.playlist.delete)
    } else if (responseJson && responseJson.errors) {
        this.setState({ errorMessage: "Something went wrong", showErr: true })
    }
}

getSongsApiResponce = (responseJson: ISongsList) => {
    if (responseJson && responseJson.data) {
        if (this.state.playlistDialogMode === "edit") {
            const savedSongs = this.state.savedSongsData
            let getSongIds: Array<string> = []
            savedSongs.forEach(({ id }) => {
                getSongIds.push(id.toString())
            })
            this.setState({
                selectedSongIdsWeb: getSongIds
            })

            this.setState({ allSonglistDataWeb: responseJson.data });

        } else {
            this.setState({ allSonglistDataWeb: responseJson.data });

        }
    } else if (responseJson && responseJson.errors) {
        this.setState({ errorMessage: "Something went wrong", showErr: true })
    }
}

viewPlaylistApiResponce = (responseJson: ViewPlayList) => {

    if (responseJson && responseJson.play_list_colection) {
        this.setState({
            playlistName: responseJson.play_list_colection.playlist_title!,
            savedSongsData: responseJson.play_list_colection.songs_details!,
            viewImageUrl: responseJson.play_list_colection.image!,
            description: responseJson.play_list_colection.description!
        },);
    } else if (responseJson && responseJson.errors) {
        this.setState({ errorMessage: "Something went wrong", showErr: true })
    }
}

handleViewPlayList = (flag: string, playList: IPlaylistcollection) => {
    this.setState({
        playlistDialog: true,
        playlistDialogMode: flag,
        selectedPlaylistWeb: playList
    }, () => {
        this.viewPlaylist(playList.id);
    })
}
viewPlaylist = (playListId: string | number | undefined) => {
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.token
    };
    const apiEndPoint = `${configJSON.showPlaylistDetails}=${playListId}`
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCallIdViewPlaylist = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndPoint);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
}
getSongsList = () => {
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCallIdGetSongs = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.songListApiEndPoint);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header));
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

savePlaylist = () => {
    const header = {
        token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const bodyformData = new FormData()
    bodyformData.append("title", this.state.playlistName);
    bodyformData.append("description", this.state.description);
    if (this.state.logo) {
        bodyformData.append("image", this.state.logo );

    }

    for (let song of this.state.selectedSongIds) {
        bodyformData.append("song_id[]", song.toString())

    }
    this.apiCallIdSavePlaylistWeb = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header));
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), bodyformData);
    if (this.state.playlistDialogMode === "add") {
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addPlayListApiEndPoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod);
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

UpdatePlaylistDataWeb = () => {
    const header = {
        token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const updateApiBodyform = new FormData()
    updateApiBodyform.append("title", this.state.playlistName);
    updateApiBodyform.append("description", this.state.description);
    if (this.state.logo) {
        updateApiBodyform.append("image", this.state.logo);

    }

    for (let song of this.state.selectedSongIds) {

        updateApiBodyform.append("song_id[]", song.toString())
    }
    this.apiCallIdSavePlaylistWeb = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header));
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), updateApiBodyform)
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateSongApiEndPoint + this.state.selectedPlaylist.id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
}

handlePlaylistClickOpenWeb = (flag: string) => {
    this.setState({
        playlistDialog: true,
        playlistDialogMode: flag,
        playlistName: "",
        selectedPlaylist: {},
        selectedSongIds: [],
    }, () => {
        this.getSongsList();
    })
}

handlePlaylistClickCloseWeb = () => {
    this.setState({
        playlistDialog: false,
        playlistDialogMode: 'add',
        selectedSongIds: [],
        selectedSongs: [],
        playlistName: "",
        description: "",
        logo: ""

    })
}

selectedSongsWeb = (selectedModel: GridSelectionModel) => {
    const selectedSong = new Set(selectedModel);
    const selectedSongs = this.state.allSonglistData.filter((list: ISongsDTO) =>
        selectedSong.has(list.id),
    );
    this.setState({
        selectedSongIdsWeb: selectedModel,
        selectedSongs: selectedSongs
    });

}

handlePlaylistName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({
        playlistName: value
    })
}
handleDescriptionWeb = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({
        description: value
    })
}
handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const logoFile = event.target.files && event.target.files[0]
    if (logoFile != null) {
        this.setState({ logo: logoFile })
    } else {
        this.setState({
            logo: ""
        })
    }

}

handleDeletePlayList = (selectedRow: IPlaylistcollection) => {
  const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
  };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.apiCallIdDeletePlaylist = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteSongApiEndPoint + selectedRow.id);
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodtype);
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
  runEngine.sendMessage(requestMessage.id, requestMessage);
}


editPlaylist = (flag: string) => {
    this.setState({
        playlistDialogMode: flag
    }, () => {
        this.getSongsList()
    })
}


  // Customizable Area End
}
